<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
    <div id="setting">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />修改密码
          </van-col>
          <van-col
            span="14"
            class="header-title"
            style="text-align: right"
            @click="onUpdateClick"
          >
            确认修改
          </van-col>
        </van-row>
      </div>
      <div class="setting-container">
        <div class="setting-body">
          <van-cell
            disabled
            :clickable="false"
            center
            :border="true"
            value="旧密码"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="user.old_password"
                type="password"
                placeholder="请输入旧密码"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            disabled
            :clickable="false"
            center
            :border="true"
            value="新密码"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="user.password"
                type="password"
                placeholder="请输入新密码"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="false"
            center
            :border="true"
            value="确认密码"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="user.password_confirmation"
                type="password"
                placeholder="请输入确认密码"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>

          <div class="btn" @click="onUpdateClick">确认修改</div>
        </div>
      </div>
    </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import cookies from "../../utils/cookies";
import oss from "@/utils/oss";
import { Toast } from "vant";
export default {
  name: "setting-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      user: {
        password: "",
        password_confirmation: "",
        old_password: "",
      },
      repassword: null,
      company: {
        name: "",
        address: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubUsersClick() {
      this.$router.push("/setting/sub-users");
    },
    async onUpdateClick() {
      if (!this.user.old_password) {
        Toast("请输入旧密码");
        return false;
      }
      if (!this.user.password) {
        Toast("请输入新密码");
        return false;
      }
      if (!this.user.password_confirmation) {
        Toast("请确认密码");
        return false;
      }
      if (this.user.password && this.user.password.length < 6) {
        Toast("密码长度必须大于6位");
        return false;
      }

      const res = await this.$apis.update_current_company({
        user: this.user,
        company: this.company,
      });
      if (res.status == 200) {
        Toast.success("修改成功！");
        this.get_current_company();
        this.$router.go(-1);
      }
    },
     async get_current_company() {
      const res = await this.$apis.get_current_company();
      this.user = res.data.user;
      this.company.name =res.data.company.name 
      this.company.name_en= res.data.company.name_en,
      this.company.address = res.data.company.address
      this.company.license_no = res.data.company.license_no //许可证号
      this.company.unit = res.data.company.unit //发证单位
      this.company.license_file_no = res.data.company.license_file_no //许可文号
      this.company.legal_person = res.data.company.legal_person
      this.company.place_of_business = res.data.company.place_of_business //经营场所
        // area_code: "",
      this.company.telephone = res.data.company.telephone //联系方式
      this.company.fax = res.data.company.fax //传真
      this.company.social_credit_card = res.data.company.social_credit_card
    },
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.user.avatar = res.fileUrl[0].split("?")[0];
        Toast.clear();
      });
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.get_current_company();
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#setting {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.setting-container {
  /* padding: 0.2rem; */
  /* height: 90vh;
  overflow-y: scroll;
  padding-bottom: 1.2rem; */
  /* width: 90%; */
  padding: 0.3rem;
}
.setting-body {
  background: #ffff;
  /* height: 90vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  /* padding: 0.3rem; */
  overflow-y: scroll;
  /* padding-bottom: 1.2rem; */
}
.setting-item {
  height: 1rem;
  line-height: 1rem;
}
.setting-item-title {
  color: #333;
  font-size: 0.3rem;
  text-align: left;
}
.setting-item-value {
  color: #333;
  font-size: 0.3rem;
  text-align: right;
}
.cell-input {
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 10px;
  text-align: right;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
